import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Link } from "gatsby";
import React, { Fragment, useContext } from "react";
import { StateContext } from "../contexts/StateContext";
import { InputRow } from "../styled";

export const TransferCompleteBody = () => {
  const { state } = useContext(StateContext);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <img
          src="/assets/images/transfer-badge.png"
          style={{ height: "10rem" }}
          alt="green check"
        />
        <div style={{ marginLeft: "2rem" }}>
          <Typography variant="h3">Transfer scheduled!</Typography>
          <Typography variant="h6">
            Estimated processing date: {state.transferDate}
          </Typography>
          <Typography variant="caption">
            Payment can be canceled up until 8PM EST on this date.
          </Typography>
        </div>
      </div>
      <InputRow>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Source Account"
          select
          fullWidth
          value={state.sourceAccount}
          style={{ margin: "2rem 1rem" }}
        >
          <MenuItem value="checking">Checking Account (****1245)</MenuItem>
        </TextField>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Amount"
          fullWidth
          type="number"
          value={state.transferAmount}
          inputProps={{ step: "0.01" }}
          style={{ margin: "2rem 1rem" }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Destination Account"
          select
          fullWidth
          value={state.destinationAccount}
          style={{ margin: "2rem 1rem" }}
        >
          <MenuItem value="chase">Chase Savings Account (****2950)</MenuItem>
        </TextField>
      </InputRow>
      <InputRow style={{ justifyContent: "center", marginTop: "1rem" }}>
        <Link to="/home">
          <Button variant="contained" color="primary" size="large">
            Home
          </Button>
        </Link>
      </InputRow>
    </Fragment>
  );
};
