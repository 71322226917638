import React, { createContext, useCallback, useState } from "react";

export const StateContext = createContext({});

export const StateContextProvider = ({ onSave, children }) => {
  const [state, setState] = useState({});

  const onSaveHandler = useCallback(() => {
    onSave && onSave(state);
  }, [onSave, state]);

  return (
    <StateContext.Provider value={{ state, setState, onSaveHandler }}>
      {children}
    </StateContext.Provider>
  );
};
