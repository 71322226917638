import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby-theme-material-ui";
import { Button, IconButton, Icon } from "@mui/material";
import { navigate } from "gatsby-link";

const NavigationBarContainer = styled.div`
  width: 100vw;
  height: 4.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  align-self: start;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 2;

  img {
    height: 3.5rem;
  }

  > a {
    font-size: 1.15rem;
    margin-left: 1.5rem;
  }

  > a.left {
    margin-left: 0;
  }
`;

export const BankNavigation = () => {
  const onLogOut = useCallback(() => {
    const cb = () => navigate("/login");

    fetch(`/api/v1/logout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(cb)
      .catch(cb);
  }, []);

  return (
    <NavigationBarContainer>
      <Link to="/home">
        <img src="/assets/images/sycamore-bank-wordmark.png" alt="bank logo" />
      </Link>
      <Link to="/home">Home</Link>
      <Link to="/transfer">Transfer</Link>
      <Link>Budget</Link>
      <div style={{ flex: 1 }}></div>
      <IconButton color="primary">
        <Icon>notifications</Icon>
      </IconButton>
      <Link to="/account" className="left">
        <IconButton color="primary">
          <Icon>account_circle</Icon>
        </IconButton>
      </Link>
      <Button color="primary" variant="contained" onClick={onLogOut}>
        Log Out
      </Button>
    </NavigationBarContainer>
  );
};
