import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { InputRow, ErrorText } from "../styled";
import { StateContext } from "../contexts/StateContext";
import { AutofillContext } from "../contexts/AutofillContext";

const accounts = {
  checking: {
    routingNum: "12210052907",
    accountNum: "2198471245",
    name: "Checking Account",
  },
  chase: {
    routingNum: "12210003945",
    accountNum: "2198472950",
    name: "Chase Savings Account",
  },
};

export const TransferMoneyBody = ({ setPage }) => {
  const { state, setState } = useContext(StateContext);
  const { setCallback, clearCallback } = useContext(AutofillContext);
  const [hasError, setHasError] = useState(null);

  const onChange = (field) => (event) => {
    event.persist();
    setState({ ...state, [field]: event.target.value });
  };

  useEffect(() => {
    setCallback(() =>
      setState((s) => ({
        ...s,
        sourceAccount: "checking",
        destinationAccount: "chase",
        transferDate: new Date().toISOString().split("T")[0],
        transferAmount: 1250,
      }))
    );

    return () => clearCallback();
  }, [setCallback, clearCallback, setState]);

  const valid = useMemo(
    () =>
      !(
        !state.sourceAccount ||
        state.sourceAccount === "none" ||
        !state.destinationAccount ||
        state.destinationAccount === "none" ||
        !state.transferDate ||
        !state.transferDate.length ||
        !state.transferAmount ||
        state.transferAmount <= 0
      ),
    [state]
  );

  const onNext = useCallback(() => {
    if (!valid) {
      setHasError("Error submitting transfer.");
    } else {
      setHasError(false);

      fetch(`/api/v1/transfer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...state,
          sourceAccount: accounts[state.sourceAccount],
          destinationAccount: accounts[state.destinationAccount],
        }),
      })
        .then((response) => {
          if (response.ok) {
            setPage("complete");
          } else {
            throw new Error("Failed to log in.");
          }
        })
        .catch(() => setHasError("Error submitting transfer."));
    }
  }, [valid, state, setPage]);

  return (
    <Fragment>
      <Typography variant="body1" style={{ marginBottom: "2rem" }}>
        Schedule a payment from a Sycamore account to an other entity. You can
        schedule a payment for a future date by using the date selector below.
        Transfers can be cancelled up until 8PM EST on the scheduled day of
        transfer.
      </Typography>
      <InputRow>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Source Account"
          select
          fullWidth
          onChange={onChange("sourceAccount")}
          value={state.sourceAccount || "none"}
        >
          <MenuItem value="none">Select account...</MenuItem>
          <MenuItem value="checking">
            Checking Account (****1245) - $18,721.54
          </MenuItem>
        </TextField>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Destination Account"
          select
          fullWidth
          onChange={onChange("destinationAccount")}
          value={state.destinationAccount || "none"}
        >
          <MenuItem value="none">Select account...</MenuItem>
          <MenuItem value="chase">Chase Savings Account (****2950)</MenuItem>
        </TextField>
      </InputRow>
      <InputRow style={{ marginTop: "2rem" }}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Schedule Date"
          fullWidth
          type="date"
          onChange={onChange("transferDate")}
          value={state.transferDate}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Amount"
          fullWidth
          type="number"
          onChange={onChange("transferAmount")}
          value={state.transferAmount}
          inputProps={{ step: "0.01" }}
        />
      </InputRow>
      <InputRow
        style={{
          marginTop: "2rem",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {hasError ? <ErrorText>{hasError}</ErrorText> : null}
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={!valid}
        >
          Send Money
        </Button>
      </InputRow>
    </Fragment>
  );
};
