import React, { useState } from "react";
import styled from "@emotion/styled";
import "../index.css";
import { BankWrapper } from "../components/BankWrapper";
import { BankNavigation } from "../components/BankNavigation";
import { TransferMoneyBody } from "../components/TransferMoneyBody";
import { SBNavOffsetContainer, StyledCard } from "../styled";
import { Typography } from "@mui/material";
import { StateContextProvider } from "../contexts/StateContext";
import { TransferCompleteBody } from "../components/TransferCompleteBody";

const CenteredOffsetContainer = styled(SBNavOffsetContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TransferContainer = styled(StyledCard)`
  width: 60rem;
`;

function ComponentBase() {
  const [page, setPage] = useState("form");

  return (
    <BankWrapper>
      <BankNavigation />
      <CenteredOffsetContainer>
        <TransferContainer>
          <Typography variant="h4" style={{ marginBottom: "3rem" }}>
            Transfer Money
          </Typography>
          {page === "form" ? <TransferMoneyBody setPage={setPage} /> : null}
          {page === "complete" ? <TransferCompleteBody /> : null}
        </TransferContainer>
      </CenteredOffsetContainer>
    </BankWrapper>
  );
}

export default function Component({ children, ...props }) {
  return (
    <StateContextProvider>
      <ComponentBase {...props}>{children}</ComponentBase>
    </StateContextProvider>
  );
}
